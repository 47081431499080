import React from 'react';
import { CardBody, Card, Row, Col } from 'reactstrap';
import { contractTerm, hoursType, renumerationType } from '@JS/model/form-schemas/CreateVacancySchema';
import { RowsWrapper } from '@Base/ValueDisplay';
import { viewVacancy } from '@JS/language/pages';
import { useLanguage } from '@Base/hooks';

function getTranslatedObj(value, obj) {
  const foundObj = obj.find((item) => item.value === value);
  let returnedStr = value;
  if (foundObj && foundObj.label) returnedStr = foundObj.label;
  return returnedStr;
}

function translateSalaryInterval(value) {
  switch (value) {
    case 'YEARLY':
      return 'Year';
    case 'MONTHLY':
      return 'Month';
    case 'WEEKLY':
      return 'Week';
    case 'DAILY':
      return 'Day';
    case 'HOURLY':
      return 'Hour';
    default:
      return value;
  }
}

const VacancyDetail = ({ vacancy }) => {
  const { langPack: languagePack } = useLanguage(viewVacancy);

  const {
    backfill,
    company: { name: companyName },
    positionProfile: {
      applicationDetail: { appName },
      title,
      description,
      category,
      industry,
      location: { formattedAddress, city, county, country },
      openVacancies,
      contractType,
      hoursType: hoursTypeValue,
      remuneration: { description: remunerationDescription, interval, ranges, type: remunerationTypeValue },
    },
    positionReference,
    preferredSkills,
  } = vacancy;

  const remRows = [
    { title: languagePack.salaryType, value: getTranslatedObj(remunerationTypeValue, renumerationType) },
    { title: languagePack.per, value: translateSalaryInterval(interval) },
    { title: languagePack.displayedSalary, value: remunerationDescription },
  ];

  let hoursTypeLabel = getTranslatedObj(hoursTypeValue, hoursType);
  if (hoursTypeLabel === 'Both') hoursTypeLabel = 'Both (Full Time / Part Time)';

  return (
    <div className="mb-3">
      <Card>
        <CardBody>
          <Row>
            <Col>
            <h4>Job Details</h4>
            </Col>
          </Row>
          <RowsWrapper
            rows={[
              { title: languagePack.companyName, value: companyName },
              { title: languagePack.title, value: title },
              { title: languagePack.reference, value: positionReference },
              { title: languagePack.vacancyType, value: hoursTypeLabel },
              { title: languagePack.positionType, value: category },
              { title: languagePack.industry, value: industry },
              { title: languagePack.contractTerm, value: getTranslatedObj(contractType, contractTerm) },
              { title: languagePack.description, html: description },
              { title: languagePack.appName, value: appName },
              { title: languagePack.openPositions, value: openVacancies },
              { title: languagePack.skills, value: preferredSkills.map((skill) => skill.name).join(', ') },
              { title: languagePack.isBackfill, value: backfill === true ? "Yes": "No" },
            ]}
          />
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <h4>Location</h4>
            </Col>
          </Row>
          <RowsWrapper rows={[{ title: languagePack.fullAddress, value: formattedAddress }]} />
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <h4>Salary</h4>
            </Col>
          </Row>
          <RowsWrapper rows={remRows} />
        </CardBody>
      </Card>
    </div>
  );
};

export default VacancyDetail;
