import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { useMounted } from '@Base/hooks';
import { retryableAPICall } from '@API/common-api-utils';
import { getTemplatesByType } from '@API/TemplateAPI';
import WorkflowSelect from './WorkflowSelect';
import { ModalPopup } from '@Base/Modal';
import { CommunicationsEditor } from '../Scheduler/components';

async function getTemplates(templateType, onSuccess = () => {}) {
  if (templateType) {
    const resp = await retryableAPICall(() => getTemplatesByType(templateType));

    if (Array.isArray(resp)) {
      onSuccess(resp);
    } else {
      toast.error('Error fetching templates, please try again.');
    }
  }
}

function EmailTemplateSelect({ id, value, onChange, actionType, required, error }) {
  const isMounted = useMounted();
  const [options, setOptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    let templateType;
    if (actionType) {
      if (actionType === 'REQUEST_ONBOARDING') {
        templateType = 'ONBOARDING';
      } else if (actionType === 'SEND_CONTACT_TEMPLATE_EMAIL') {
        templateType = 'CANDIDATE_CONTACT';
      } else if (actionType === 'REQUEST_RTW') {
        templateType = 'RIGHT_TO_WORK_REQUEST';
      } else if (actionType === 'REQUEST_EDOC') {
        templateType = 'EDOC';
      } else if (actionType === 'REQUEST_GENERIC_FORM') {
        templateType = 'GENERIC_FORM';
      } else if (actionType === 'REQUEST_PERSONALITY_TEST') {
        templateType = 'PERSONALITY_TEST_REQUEST';
      } else if (actionType === 'ATS_USER') {
        templateType = 'ATS_USER';
      }
    }

    getTemplates(templateType, (resp) => {
      const opts = resp.map(({ id: tmpId, name }) => ({ label: name, value: tmpId }));
      if (isMounted()) setOptions(opts);
    });
  }, [actionType, isMounted]);

  return (
    <>
      <WorkflowSelect
        error={error}
        id={id}
        label="Email Template"
        onChange={onChange}
        options={options}
        placeholder="Choose Template"
        required={required}
        value={value}
      >
        {actionType === 'SEND_CONTACT_TEMPLATE_EMAIL' && (
          <Button className="px-0" color="link" onClick={() => setModalOpen(true)} size="sm">
            Create new Candidate Contact Email
          </Button>
        )}
      </WorkflowSelect>
      <ModalPopup
        hideCancelButton
        hideOkayButton
        isOpen={modalOpen}
        maxHeight="80vh"
        onToggle={() => setModalOpen(false)}
        title="Create Candidate Contact Email"
      >
        <CommunicationsEditor
          onCancel={() => setModalOpen(false)}
          onSave={({ id: emlId, name }) => {
            setOptions([...options, { value: emlId, label: name }]);
            onChange(emlId);
            setModalOpen(false);
          }}
          strictType="CANDIDATE_CONTACT"
        />
      </ModalPopup>
    </>
  );
}

EmailTemplateSelect.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  actionType: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
};

EmailTemplateSelect.defaultProps = {
  id: null,
  value: null,
  onChange: () => {},
  actionType: null,
  required: false,
  error: null,
};

export default EmailTemplateSelect;
