import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';

import { useMounted } from '@Base/hooks';
import { getTags } from '@Base/Tags/utils';

function TagSelectInput({ option, setOption }) {
  const isMounted = useMounted();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (isMounted() && !tags.length) {
      getTags(
        (tagArr) =>
          setTags(
            tagArr.map((a) => {
              return {
                label: a.name,
                value: a.name,
              };
            }),
          ),
        () => toast.error('Error fetching tags'),
      );
    }
  }, [isMounted, setTags, tags]);

  return (
    <>
      <FormGroup required>
        <Label>Change type</Label>
        <Select
          className="react-select"
          placeholder="Select tag action"
          options={[
            { label: 'Add', value: 'CREATE' },
            { label: 'Remove', value: 'DELETE' },
          ]}
          value={[
            { label: 'Add', value: 'CREATE' },
            { label: 'Remove', value: 'DELETE' },
          ].find((a) => a.value === option?.tagAction)}
          onChange={(val) => setOption({ tagAction: val.value })}
        />
      </FormGroup>
      <FormGroup required>
        <Label>Tags</Label>
        <Select
          required
          isMulti
          className="react-select"
          placeholder="Select Tags"
          options={tags}
          value={tags.reduce((acc, cur) => {
            if (option?.tags?.includes(cur.value)) acc.push(cur);
            return acc;
          }, [])}
          onChange={(val) => {
            setOption({ tags: val.map((a) => a.value) });
          }}
        />
      </FormGroup>
    </>
  );
}

TagSelectInput.propTypes = {
  option: PropTypes.object,
  setOption: PropTypes.func,
};

TagSelectInput.defaultProps = {
  option: [],
  setOption: () => {},
};

export default TagSelectInput;
