import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormGroup, Label, Input } from 'reactstrap';
import FunnelSelectInputs from '@Base/Forms/Custom/FunnelSelect/FunnelSelectInputs';
import EventTypeSelect from '../EventTypeSelect';
import {
  payrollStates,
  defaultStates,
  referenceStates,
  rtwStates,
  rtwOutcomes,
  personalityTestState,
  personalityTestScoreValues,
  personalityTestScoreTypes,
  eventStates,
  newCandidateFilterTypes,
  documentStatusTypes
} from '../../../../EventOptions';
import TagSelectInput from './TagSelectInput';
import SelectFormInput from './SelectFormInput';

function InputNode({ selectedNode, onChange }) {
  if (selectedNode?.actionType === 'FUNNEL_STATE_CHANGE') {
    return (
      <>
        <FormGroup required>
          <FunnelSelectInputs
            funnelSelectProps={{
              id: `actionFunnelId`,
              label: 'Funnel',
              required: true,
              value: selectedNode?.funnelId || '',
            }}
            onChange={(val) => onChange({ funnelId: val.funnelId, stageId: val.stageId })}
            stageSelectProps={{
              id: `actionStageId`,
              label: 'Stage',
              required: true,
              value: selectedNode?.stageId || '',
            }}
          />
        </FormGroup>
      </>
    );
  }

  if (selectedNode?.actionType === 'SINGLE_EDOC_STATUS_CHANGE') {
    return <>
      <FormGroup>
        <Label>Edoc</Label>
        <SelectFormInput option={selectedNode?.docIds} setOption={(docIds)=>{onChange({ docIds:docIds })}} />
      </FormGroup>
      <FormGroup>
        <Label>Statuses</Label>
        <Select
          classNamePrefix="react-select"
          isMulti
          onChange={(status) => {
            const vals = status.map((a)=> a.value)
            onChange({ status:vals })
          }}
          options={documentStatusTypes}
          placeholder="Choose Category"
          value={selectedNode.status?.map((value) =>
            documentStatusTypes.find((option) => option.value === value),
          )}
        />
      </FormGroup>
    </>
  }
  if (selectedNode?.actionType === 'PAYROLL_INTEGRATION_SUBMISSION') {
    return (
      <FormGroup required>
        <Label>
          Status<span className="required">*</span>
        </Label>
        <Select
          required
          className="react-select"
          placeholder="Select Status"
          options={payrollStates}
          value={payrollStates.find((a) => a.value === selectedNode?.condition)}
          onChange={(val) => onChange({ condition: val.value })}
        />
      </FormGroup>
    );
  }

  if (
    selectedNode?.actionType === 'ONBOARDING_STATUS_CHANGE' ||
    selectedNode?.actionType === 'EDOC_STATUS_CHANGE' ||
    selectedNode?.actionType === 'DBS_STATUS_CHANGE' ||
    selectedNode?.actionType === 'REFEREE_STATUS_CHANGE' ||
    selectedNode?.actionType === 'GENERIC_FORM_STATUS_CHANGE'
  ) {
    const foundValue = defaultStates.find((a) => a.value === selectedNode?.condition);

    return (
      <FormGroup required>
        <Label>
          Status<span className="required">*</span>
        </Label>
        <Select
          required
          className="react-select"
          placeholder="Select Status"
          options={defaultStates}
          value={foundValue}
          onChange={(val) => onChange({ condition: val.value })}
        />
      </FormGroup>
    );
  }

  if (selectedNode?.actionType === 'REFERENCE_STATUS_CHANGE') {
    return (
      <FormGroup required>
        <Label>
          Status<span className="required">*</span>
        </Label>
        <Select
          required
          className="react-select"
          placeholder="Select Status"
          options={referenceStates}
          value={referenceStates.find((a) => a.value === selectedNode?.condition)}
          onChange={(val) => onChange({ condition: val.value })}
        />
      </FormGroup>
    );
  }

  if (selectedNode?.actionType === 'TAG_CHANGE') {
    return <TagSelectInput value={selectedNode} onChange={onChange} />;
  }

  if (selectedNode?.actionType === 'RTW_STATUS_CHANGE') {
    return (
      <div>
        <FormGroup required>
          <Label>
            Status<span className="required">*</span>
          </Label>
          <Select
            className="react-select"
            placeholder="Select Status"
            options={rtwStates}
            value={rtwStates.find((a) => a.value === selectedNode?.condition)}
            onChange={(val) => onChange({ condition: val.value })}
          />
        </FormGroup>
        {selectedNode?.condition === 'RECEIVED' && (
          <FormGroup required>
            <Label>
              Outcome<span className="required">*</span>
            </Label>
            <Select
              required
              className="react-select"
              placeholder="Select Validity"
              options={rtwOutcomes}
              value={rtwOutcomes.find((a) => a.value === selectedNode?.rtwValidity)}
              onChange={(val) => {
                onChange({ rtwValidity: val.value });
              }}
            />
          </FormGroup>
        )}
      </div>
    );
  }

  if (selectedNode?.actionType === 'PERSONALITY_TEST_STATUS_CHANGE') {
    return (
      <div>
        <FormGroup required>
          <Label>
            Status<span className="required">*</span>
          </Label>
          <Select
            className="react-select"
            placeholder="Select Status"
            options={personalityTestState}
            value={personalityTestState.find((a) => a.value === selectedNode?.condition)}
            onChange={(val) => onChange({ condition: val.value })}
          />
        </FormGroup>
        {selectedNode?.condition === 'RECEIVED' && (
          <FormGroup required>
            <Label>
              When Score is<span className="required">*</span>
            </Label>
            <Select
              className="react-select"
              placeholder="Select Outcome"
              options={personalityTestScoreValues}
              value={personalityTestScoreValues?.find((a) => a?.value === selectedNode?.operator?.value)}
              onChange={(val) => onChange({ operator: val })}
            />
          </FormGroup>
        )}
        {(selectedNode?.condition === 'RECEIVED') &
        (selectedNode?.operator?.value === 'LT' ||
          selectedNode?.operator?.value === 'EQ' ||
          selectedNode?.operator?.value === 'GT') ? (
          <FormGroup required>
            <Label>
              Score Value<span className="required">*</span>
            </Label>
            <Input
              onChange={(e) => {
                onChange({ scoreValue: e.target.value });
              }}
              type="text"
              value={selectedNode?.scoreValue}
            />
          </FormGroup>
        ) : null}
        {selectedNode?.condition === 'RECEIVED' &&
        (selectedNode?.operator?.value === 'LT' ||
          selectedNode?.operator?.value === 'EQ' ||
          selectedNode?.operator?.value === 'GT') ? (
          <FormGroup required>
            <Label>
              Score Type<span className="required">*</span>
            </Label>
            <Select
              className="react-select"
              placeholder="Select Score type"
              options={personalityTestScoreTypes}
              value={personalityTestScoreTypes.find((a) => a.value === selectedNode?.scoreType?.value)}
              onChange={(val) => onChange({ scoreType: val })}
            />
          </FormGroup>
        ) : null}
      </div>
    );
  }
  if (selectedNode?.actionType === 'EVENT_STATUS_CHANGE') {
    return (
      <>
        <FormGroup required>
          <Label>
            Status<span className="required">*</span>
          </Label>
          <Select
            className="react-select"
            placeholder="Select Status"
            options={eventStates}
            value={eventStates.find((a) => a.value === selectedNode?.condition)}
            onChange={(val) => onChange({ condition: val.value })}
          />
        </FormGroup>
        <EventTypeSelect
          value={selectedNode?.eventType}
          error={null}
          onChange={(eventType) => onChange({ eventType })}
        />
      </>
    );
  }

  if (selectedNode?.actionType === 'CREATE_CANDIDATE_EVENT') {
    return (
      <>
        <FormGroup required>
          <Label>Candidate Filter Type</Label>
          <Select
            className="react-select"
            placeholder="Select Status"
            options={newCandidateFilterTypes}
            value={newCandidateFilterTypes?.find((a) => a?.value === selectedNode?.condition)}
            onChange={(val) => {
              onChange({ condition: val.value });
            }}
          />
        </FormGroup>
        {(selectedNode?.condition === 'UTMSOURCE' ||
          selectedNode?.condition === 'UTMCAMPAIGN' ||
          selectedNode?.condition === 'UTMMEDIUM') && (
          <FormGroup required>
            <Label>
              Value<span className="required">*</span>
            </Label>
            <Input
              onChange={(e) => onChange({ candidateFilterVal: e.target.value })}
              type="text"
              placeholder="Set value to filter this workflow on"
              value={selectedNode?.candidateFilterVal}
            />
          </FormGroup>
        )}
        {selectedNode?.condition === 'ALL' && (
          <FunnelSelectInputs
            funnelSelectProps={{
              id: `actionFunnelId`,
              label: 'Funnel',
              required: true,
              value: selectedNode?.funnelId || '',
            }}
            onChange={(val) => onChange({ funnelId: val.funnelId, stageId: val.stageId })}
            stageSelectProps={{
              id: `actionStageId`,
              label: 'Stage',
              required: true,
              value: selectedNode?.stageId || '',
              hidden: true,
            }}
            alwaysShowStage
          />
        )}
      </>
    );
  }
}

InputNode.propTypes = {
  selectedNode: PropTypes.shape(),
  onChange: PropTypes.func,
};

InputNode.defaultProps = {
  selectedNode: {},
  onChange: () => {},
};

export default InputNode;
