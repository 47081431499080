import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Tabs from '@Base/Tabs/Tabs';
import { DataTable } from '@Base/Tables';
import { ActionButton, CreateButton } from '@Base/Buttons';
import { Confirmation } from '@Base/Modal';
import Slider from '@Base/Slider/Slider';
import { LoadingScreen } from '@Base/Loading';
import { useLanguagePack, useMounted } from '@Base/hooks';
import { TippyTruncate } from '@Base/Truncate';
import Can from '@Base/RBAC/Can/Can';

import { retryableAPICall } from '@API/common-api-utils';
import { deleteTemplate, emailTemplateSearch } from '@API/CommsAPI/EmailTemplateAPI';

import { checkPermissions } from '@JS/auth/AuthUtils';

import EmailTemplateForm from './EmailTemplateForm';
import NoEmailTemplateSplash from './NoEmailTemplateSplash';

import { EnhancedCard, EnhancedCardTitle, PageColumn, SearchInput } from '../../Common';
import { useDebounce } from '../../Common/utils';
import NotificationConfiguration from './NotificationConfiguration';

const RestrictedCreateButton = Can(CreateButton);

const StyledTable = styled(DataTable)`
  .rt-thead {
    background-color: #f8f9fa !important;
    padding: 0.35rem 0;
  }
`;

function getUniqueItems(data) {
  const dictOfUniqueAccounts = data.reduce((uniqueItems, currentItem) => {
    const identifier = `${currentItem.accountId}-${currentItem.accountName}`;

    if (!uniqueItems[identifier]) {
      uniqueItems[identifier] = currentItem;
    }

    return uniqueItems;
  }, {});

  return Object.values(dictOfUniqueAccounts);
}

function EmailTemplates({ isCreateMode, onClose, updateCreateMode, totalAccounts, setIsCreateMode }) {
  const isMounted = useMounted();
  const languagePack = useLanguagePack('client-settings');
  const [activeTab, setActiveTab] = useState('personal');
  const [tableData, setTableData] = useState([]);
  const [viewedTemplate, setViewedTemplate] = useState(undefined);
  const [sliderOpen, setSliderOpen] = useState(isCreateMode);
  const [errorMsg, setErrorMsg] = useState();
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [isResolved, setIsResolved] = useState(false);
  const [openActionId, setOpenActionId] = useState('');
  const [displayDeleteWarning, setDisplayDeleteWarning] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const getData = useCallback(async () => {
    const templates = [
      'REJECTION',
      'CANDIDATE_CONTACT',
      'REFEREE_REQUEST',
      'REFERENCE_REQUEST',
      'REFERENCE_REQUEST_CHASE',
      'REFERENCE_REQUEST_CHASE_APPLICANT',
      'REFERENCE_RECEIVED',
      'APPLICATION_COMPLETE',
      'ATS_USER',
    ];

    if (checkPermissions(['rtw:read'])) {
      templates.push('RIGHT_TO_WORK_REQUEST', 'RIGHT_TO_WORK_REQUEST_REMINDER_1', 'RIGHT_TO_WORK_REQUEST_REMINDER_2');
    }
    if (checkPermissions(['pt:read', 'pth:read'])) {
      templates.push(
        'PERSONALITY_TEST_REQUEST',
        'PERSONALITY_TEST_REQUEST_REMINDER_1',
        'PERSONALITY_TEST_REQUEST_REMINDER_2',
      );
    }
    if (checkPermissions(['candidate:onboarding:read'])) {
      templates.push('ONBOARDING', 'ONBOARDING_REQUEST_CHASE');
    }
    if (checkPermissions(['candidate:edoc:read'])) {
      templates.push('EDOC', 'EDOC_REQUEST_CHASE', 'EDOC_CANDIDATE_COPY');
    }
    if (checkPermissions(['candidate:genericforms:read'])) {
      templates.push('GENERIC_FORM', 'GENERIC_FORM_REQUEST_CHASE');
    }

    if (checkPermissions(['admin:schedule:read'])) {
      templates.push('INVITE_TO_INTERVIEW');
    }

    if (checkPermissions([])) {
      templates.push('JOBSEEKER_REGISTRATION', 'JOBSEEKER_PASSWORDLESS_LOGIN_REQUEST');
    }

    const results = await retryableAPICall(() =>
      emailTemplateSearch(debouncedSearchTerm, [
        { field: 'group', operation: 'EQ', value: [activeTab.toUpperCase()] },
        {
          field: 'type',
          operation: 'IN',
          value: templates,
        },
      ]),
    );

    if (isMounted()) {
      if (Array.isArray(results)) setTableData(results);
      setIsResolved(true);
    }
  }, [activeTab, isMounted, debouncedSearchTerm]);

  useEffect(() => {
    getData();
  }, [activeTab, getData]);

  useEffect(() => {
    if (isMounted()) {
      setViewedTemplate(undefined);
      setSliderOpen(isCreateMode);
    }
  }, [isCreateMode, isMounted]);

  function handleDelete(template) {
    setDeleteCandidate({ id: template.id, name: template.name, group: template.group });
    setDisplayDeleteWarning(true);
  }

  async function doDelete() {
    const result = await retryableAPICall(() => deleteTemplate(deleteCandidate.id, deleteCandidate.group));

    if (typeof result === 'string' && result !== '') {
      toast.error(`There was an error deleting the template ${deleteCandidate.name}`);
    } else {
      toast.success(`Successfully deleted template ${deleteCandidate.name}`);
    }
    setDeleteCandidate(null);
    await getData();
  }

  function onSuccess() {
    toast.success('Successfully saved template');
    getData();
    setSliderOpen(false);
  }

  function handleClose() {
    setSliderOpen(false);
    setIsCopyMode(false);
    setTimeout(() => {
      onClose();
    }, 500);
  }

  function onError() {
    toast.error('There was an error saving the template');
  }

  const columns = [
    {
      Header: 'NAME',
      id: 'templateName',
      minWidth: 100,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        const { name } = original;

        if (checkPermissions(['admin:emails:update'])) {
          return (
            <a
              href="#candidateselection"
              onClick={(e) => {
                e.preventDefault();
                // eslint-disable-next-line no-use-before-define
                setViewedTemplate(original);
                // eslint-disable-next-line no-use-before-define
                setSliderOpen(true);
              }}
            >
              {name}
            </a>
          );
        }

        return name;
      },
    },
    {
      Header: 'SUBJECT',
      id: 'subject',
      accessor: 'subject',
      minWidth: 100,
    },
    {
      Header: 'TYPE',
      id: 'type',
      accessor: 'type',
      width: 160,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => (
        // eslint-disable-next-line react/prop-types,implicit-arrow-linebreak
        <TippyTruncate>{languagePack[original.type] ? languagePack[original.type] : original.type}</TippyTruncate>
      ),
    },
    {
      Header: 'CREATED',
      id: 'createdDateTime',
      accessor: (r) => moment(r.createdDateTime).format('DD-MM-YYYY'),
      width: 125,
    },
  ];

  if (activeTab === 'company' && checkPermissions(['admin:emails:share'])) {
    columns.push({
      Header: 'SHARED WITH',
      id: 'share',
      width: 200,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types,prefer-const
        let { sharedWith = [] } = original;

        const filteredAccounts = getUniqueItems(sharedWith);

        if (!filteredAccounts.length) {
          return null;
        }

        if (filteredAccounts.length === totalAccounts) {
          return 'All';
        }

        const accountNames = filteredAccounts.map(({ accountName }) => accountName).join(', ');

        return <TippyTruncate>{accountNames}</TippyTruncate>;
      },
    });
  }

  if (checkPermissions(['admin:emails:update', 'admin:emails:delete'])) {
    columns.push({
      accessor: 'actions',
      className: 'action-cell',
      width: 90,
      id: 'actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { id, deletable, typeChange } = original;

        const menuItems = [
          {
            id: 1,
            label: 'Copy',
            icon: 'Copy',
            permissions: ['admin:emails:update'],
            action: () => {
              setViewedTemplate({ ...original, name: `${original.name} (copy)`, id: null });
              setOpenActionId(null);
              setSliderOpen(true);
              setIsCopyMode(true);
            },
          },
          {
            id: 2,
            label: 'Delete',
            icon: 'Bin',
            permissions: ['admin:emails:delete'],
            isDisabled: !deletable,
            // eslint-disable-next-line max-len
            tooltipContent:
              "This email is required by the system and can't be removed, if you wish to change the contents you can edit it",
            action: () => {
              handleDelete(original);
              setOpenActionId(null);
            },
          },
        ];

        if (typeChange) {
          menuItems.unshift({
            id: 0,
            label: 'Edit',
            icon: 'Pencil',
            permissions: ['admin:emails:update'],
            action: () => {
              // eslint-disable-next-line no-use-before-define
              setViewedTemplate(original);
              // eslint-disable-next-line no-use-before-define
              setSliderOpen(true);
              setOpenActionId(null);
            },
          });
        }

        return (
          <ActionButton
            isOpen={openActionId === id}
            menuItems={menuItems}
            menuStyle={{ width: '150px' }}
            onToggle={(isOpen) => setOpenActionId(isOpen ? id : '')}
            position="left"
            subMenuProps={{
              menuStyle: { width: '100px' },
              position: 'left',
            }}
            title="Action"
          />
        );
      },
    });
  }

  if (tableData && tableData.length === 0 && !isResolved) return <LoadingScreen isEmbeded />;

  return (
    <>
      <PageColumn permissions={['admin:emails:read']} className={'pt-0'}>
        <Row className="pb-3">
          <Col className="mx-auto">
            <div style={{ maxWidth: '1600px', width: '100%', margin: '0 auto' }}>
              <Row className="tab-row">
                <Col className="ps-0">
                  <Tabs
                    activeTab={activeTab}
                    className="email-settings-tabs mb-1"
                    onClick={(id) => setActiveTab(id)}
                    positionAbsolute={false}
                    tabs={[
                      { anchor: 'personal', label: 'Personal' },
                      { anchor: 'company', label: 'Company', permissions: [] },
                      { anchor: 'system', label: 'System' },
                      { anchor: 'config', label: 'Configuration', permissions: ['talentfunnel:admin'] },
                    ]}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mx-auto">
            <EnhancedCard>
              {activeTab === 'config' && <NotificationConfiguration />}
              {activeTab !== 'config' && (
                <>
                  <EnhancedCardTitle
                    title={activeTab === 'personal' ? 'Personal' : activeTab === 'company' ? 'Company' : 'System'}
                    subtitle={`Create and update your ${activeTab === 'personal' ? 'Personal' : activeTab === 'company' ? 'Company' : 'System'} templates`}
                  >
                    <RestrictedCreateButton
                      action={() => setIsCreateMode(!isCreateMode)}
                      label={`Create Email Template`}
                      permissions={['admin:emails:create']}
                      className="mb-auto"
                    />
                  </EnhancedCardTitle>
                  <div className="d-flex align-items-center gap-3 ">
                    <SearchInput
                      value={searchTerm}
                      className="w-100"
                      type="text"
                      placeholder="Search Templates"
                      onChange={(e) => setSearchTerm(e)}
                    />
                  </div>
                  {tableData && tableData.length === 0 && isResolved && (
                    <NoEmailTemplateSplash
                      onButtonClick={() => {
                        setSliderOpen(true);
                        updateCreateMode(true);
                      }}
                      tab={activeTab}
                    />
                  )}
                  {tableData && tableData.length > 0 && (
                    <Row>
                      <Col>
                        <StyledTable
                          className="mt-3"
                          columns={columns}
                          data={tableData}
                          errorText="We can't load the email templates right now please try again later"
                          hasSelectColumn={false}
                          id="email-templates"
                          isLoading={false}
                          isResolved={false}
                          noDataText="There are no email templates"
                          onChange={getData}
                          pageSize={50}
                          rowHeight={51}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </EnhancedCard>
          </Col>
        </Row>
      </PageColumn>
      {(viewedTemplate || isCreateMode) && (
        <Slider
          className="group-action-slider"
          errorMsg={errorMsg}
          focusForm={false}
          isOpen={sliderOpen}
          resetError={setErrorMsg}
          resetSuccess={setSuccessMsg}
          successMsg={successMsg}
          title={viewedTemplate ? 'Edit Template' : 'Create Template'}
          toggleOpen={handleClose}
          width="100%"
        >
          <EmailTemplateForm
            emailTemplate={viewedTemplate}
            isCopyMode={isCopyMode}
            templateGroup={activeTab}
            onError={onError}
            onSuccess={(msg) => {
              onSuccess(msg);
              handleClose();
            }}
          />
        </Slider>
      )}
      <Confirmation
        cancelCallback={() => setDisplayDeleteWarning(false)}
        confirmCallback={() => {
          doDelete();
          setDisplayDeleteWarning(false);
        }}
        content={`Are you sure you want to delete template ${deleteCandidate && deleteCandidate.name}`}
        show={displayDeleteWarning}
        title="Delete Template?"
      />
    </>
  );
}

EmailTemplates.propTypes = {
  isCreateMode: PropTypes.bool,
  onClose: PropTypes.func,
  updateCreateMode: PropTypes.func,
  totalAccounts: PropTypes.number,
};

EmailTemplates.defaultProps = {
  isCreateMode: false,
  updateCreateMode: () => {},
  onClose: () => {},
  totalAccounts: 0,
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: {
        data: { accountAccess = [] },
      },
    },
  } = state;
  return {
    totalAccounts: accountAccess.length,
  };
}

export default connect(mapStateToProps, null)(EmailTemplates);
