// eslint-disable-next-line no-unused-vars
import React, { useState, useReducer, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col, Badge } from 'reactstrap';
import { toast } from 'react-toastify';
import withRouter from '../../../../hoc/withRouter';
import {
  getAllJobseekerApplications,
  getApplicant,
  getCandidateProfile,
  getCandidateProfileSummary,
} from '@API/CandidateAPI/CandidateAPI';
import { addVacancy } from '@JS/actions/vacancyActions';
import { retryableAPICall } from '@API/common-api-utils';
import { getVacancyFunnel, getVacancy } from '@API/VacancyAPI';
import CandidateSlider from '@Base/Slider/CandidateSlider';
import Tabs from '@Base/Tabs/Tabs';
import { FunnelManager, FunnelContext, EmailForm, SMSForm, AttachmentForm, NoteForm } from '../../../Communication';
import CandidateDetails from '../CandidateDetails/CandidateDetails';
import CandidateContactSummary from './CandidateContactSummary';
import { CandidateNotes, CandidateTimeline } from '../../Timelines';
import { AttachmentsContainer } from '../../Attachments';
import OnboardingDetails from '../../Onboarding/OnboardingDetails';
import InterviewsContainer from '../../Interviews/InterviewsContainer';
import ReferenceDetailsContainer from '../../References/ReferenceDetailsContainer';
import InterviewForm from '../../../Scheduler/InterviewForm';
import OtherApplicationsContainer from '../../OtherApplications/OtherApplicationsContainer';
import {
  UPDATE_FUNNEL,
  UPDATE_EMAIL,
  UPDATE_SMS,
  // UPDATE_NOTE,
  UPDATE_ATTACHMENTS,
  candidateDataReducer,
} from './candidateDataCache';
import { useLanguagePack, useMounted } from '@Base/hooks';
import RightToWorkDetails from '../../RightToWork/RightToWorkDetails';
import PersonalityTestDetails from '../../PersonalityTest/PersonalityTestDetails';
import { IconSVG } from '@Base/SVG';
import ChatContainer from '../Chat/ChatContainer';
import EDocDetailsContainer from '../../EDocs/EDocDetailsContainer';
import GenericFormsContainer from '../../GenericForms/GenericFormsContainer';
import { checkPermissions } from '@JS/auth/AuthUtils';
import DbsCheckDetails from '../../DbsCheck/DbsCheckDetails';
import { getDbsCheckRequestStatus } from '@API/DbsAPI/DbsAPI';
import { WorkflowContext } from '@Base/contexts/WorflowContext';

function getLatestPersonalityStatus(statusHistory) {
  if (!statusHistory || statusHistory.length === 0) {
    return 'NOT_REQUESTED';
  }

  const orderedStatuses = statusHistory?.sort((a, b) => {
    return new Date(b.statusDateTime) - new Date(a.statusDateTime);
  });

  return orderedStatuses[0].status;
}

export const candidateToolTabs = [
  {
    anchor: 'funnel',
    icon: 'Talent Funnel',
    label: 'funnelStage',
  },
  {
    anchor: 'email',
    icon: 'Email',
    label: 'email',
    permissions: ['communication:candidate:create'],
  },
  { anchor: 'interview', icon: 'Calendar', label: 'interview' },
  {
    anchor: 'attachments',
    icon: 'Paper Clip',
    label: 'attachments',
    permissions: ['candidate:attachments:create'],
  },
  {
    anchor: 'notes',
    icon: 'File',
    label: 'notes',
    permissions: ['candidate:notes:create'],
  },
  {
    anchor: 'sms',
    icon: 'Comment',
    label: 'SMS',
    permissions: ['communication:sms'],
  },
];

function deleteAttachment(fileArr, deletedFile) {
  const cloneFiles = [...fileArr];
  const idx = cloneFiles.findIndex(({ id }) => id === deletedFile.id);
  cloneFiles.splice(idx, 1);
  return cloneFiles;
}

function composeApiCall(apiCallName) {
  return (args) => async () => apiCallName(...args);
}

function composeApiCallWithSource(apiCallName, source) {
  return (args) => async () =>
    apiCallName(...args).then((resp) => {
      return {
        ...resp,
        source: source,
      };
    });
}

const vacancyApiCall = composeApiCall(getVacancy);
const funnelApiCall = composeApiCall(getVacancyFunnel);
const otherApplicationsApiCall = composeApiCall(getAllJobseekerApplications);
const candidateSummaryApiCall = composeApiCall(getCandidateProfileSummary);
const candidateProfileApiCall = composeApiCall(getCandidateProfile);
const dbsCheckProfileApiCall = composeApiCallWithSource(getDbsCheckRequestStatus, 'dbs-check');

function CandidateView({
  vacancyTitle,
  vacancyId,
  userId,
  applicantId,
  accountName,
  funnelId: funnel,
  vacancies,
  defaultTab,
  defaultDetailsTab,
  handleCandidateUpdate,
  applyAddVacancy,
  onClose,
  embedded,
  emailAddress,
  ...rest
}) {
  const [activeTab, setActiveTab] = useState('funnel');
  const [activeDetailsTab, setActiveDetailsTab] = useState('appDetails');
  const [isLoadingStages, setIsLoadingStages] = useState(false);
  const [funnelId, setFunnelId] = useState(funnel);
  const [funnelCache, setFunnelCache] = useState({ funnelId: '', vacancyId: '' });
  const [funnelStages, setFunnelStages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notesKey, setNotesKey] = useState('');
  const [otherApps, setOtherApps] = useState([]);
  const [profileSummary, setProfileSummary] = useState();
  const [candidateProfile, setCandidateProfile] = useState();
  const [dbsCheckRequestRecord, setDbsCheckRequestRecord] = useState();
  const [sliderData, dispatchSliderData] = useReducer(candidateDataReducer, {});
  const isMounted = useMounted();
  const isFunnelRetried = useRef(false);
  const languagePack = useLanguagePack('candidate-view');

  const handleFunnelLoad = useCallback((resp, fId) => {
    if (typeof resp === 'string') {
      toast.error('Error loading funnels, please try again.');
    } else if (typeof resp === 'object' && resp.stages) {
      setFunnelId(fId);
      setFunnelStages(resp.stages);
    }
    setIsLoadingStages(false);
  }, []);

  useEffect(() => {
    if (defaultTab) setActiveTab(defaultTab);
    if (defaultDetailsTab) setActiveDetailsTab(defaultDetailsTab);
  }, [defaultDetailsTab, defaultTab]);

  useEffect(() => {
    let vacancyFunnelId = funnel;

    if (!funnel && vacancies && vacancyId) {
      vacancyFunnelId = vacancies[vacancyId].funnelId;
    }

    const fetchData = async () => {
      setIsLoading(true);

      const fetchArr = [];

      if (vacancyId && vacancyFunnelId) {
        if (vacancyId !== funnelCache.vacancyId || vacancyFunnelId !== funnelCache.funnelId) {
          setFunnelCache({ funnelId: vacancyFunnelId, vacancyId });
          setIsLoadingStages(true);
          fetchArr.push(funnelApiCall([vacancyId, vacancyFunnelId]));
        }
      }

      if (vacancyId && !vacancies[vacancyId]) {
        fetchArr.push(vacancyApiCall([vacancyId]));
      }

      if (applicantId) {
        fetchArr.push(candidateSummaryApiCall([applicantId]));
      }
      if (applicantId) fetchArr.push(candidateProfileApiCall([applicantId]));
      if (emailAddress) fetchArr.push(otherApplicationsApiCall([emailAddress]));
      if (applicantId) fetchArr.push(dbsCheckProfileApiCall([applicantId]));

      const respArr = await Promise.all(fetchArr.map((apiCall) => retryableAPICall(() => apiCall())));

      const hasRespError = respArr.some((resp) => typeof resp === 'string');

      if (hasRespError) toast.error('Error loading data, please try again.');
      respArr.forEach((resp) => {
        // funnel response
        if (resp.stages) {
          handleFunnelLoad(resp, vacancyFunnelId);
        }
        // other apps response
        else if (Array.isArray(resp) && resp.length && resp[0].submittedDateTime) {
          setOtherApps(resp.filter((app) => app.id !== applicantId));
        }
        // summary resp
        else if (resp.notes) {
          setProfileSummary(resp);
        } else if (resp.funnelHistory) {
          setCandidateProfile(resp);
        } else if (resp.posterDetails) {
          applyAddVacancy(resp, vacancyId);
        } else if ('dbs-check' === resp.source) {
          setDbsCheckRequestRecord(resp);
        }
      });

      setIsLoading(false);
    };
    if (isMounted()) fetchData();
  }, [
    applicantId,
    funnel,
    funnelCache.funnelId,
    funnelCache.vacancyId,
    handleFunnelLoad,
    userId,
    vacancies,
    vacancyId,
    isMounted,
  ]);

  async function retryFunnel(vId, fId) {
    isFunnelRetried.current = true;
    setFunnelCache({ funnelId: fId, vacancyId: vId });
    setIsLoadingStages(true);
    const resp = await retryableAPICall(() => getVacancyFunnel(vId, fId));
    handleFunnelLoad(resp, fId);
  }

  async function handleSummaryUpdate() {
    if (applicantId) {
      const resp = await retryableAPICall(() => getCandidateProfileSummary(applicantId));
      if (typeof resp === 'object' && resp !== null) {
        setProfileSummary(resp);
        handleCandidateUpdate(applicantId, null, { profileSummary: resp });
      }
    }
  }

  function getMultiStatus(item) {
    let eDocStatus;
    if (item && item.length === 0) {
      eDocStatus = 'NOT_REQUESTED';
    } else if (item && item.length === 1) {
      eDocStatus = item[0].status;
    } else if (Array.isArray(item)) {
      let status = 'MULTI';

      const filteredStatuses = item?.filter((item) => item?.status !== 'WITHDRAWN');
      if (filteredStatuses && filteredStatuses.length === 0) {
        status = 'NOT_REQUESTED';
      } else if (filteredStatuses && filteredStatuses.length === 1) {
        status = filteredStatuses[0].status;
      } else {
        if (filteredStatuses?.every((item) => item?.status === 'RECEIVED')) {
          status = 'RECEIVED';
        }
      }
      eDocStatus = status;
    }

    return eDocStatus || 'NOT_REQUESTED';
  }
  return (
    <CandidateSlider
      {...rest}
      apiMethod={getApplicant}
      applicantId={applicantId}
      onClose={onClose}
      onLoad={(applicantData) => {
        if (!isLoadingStages && !funnelStages.length && !isFunnelRetried.current) {
          const { funnelId: appFunnelId, vacancyId: appVacId } = applicantData;
          retryFunnel(appVacId, appFunnelId);
        }
      }}
      title="Edit Profile"
    >
      {({ isLoading: sliderLoading, fullName, applicantData = {}, onSuccess, onError, onApplicantUpdate }) => {
        if (sliderLoading && isLoading) return null;

        if (!applicantData || !Object.keys(applicantData).length) return null;

        const {
          accountId,
          id,
          funnelStageId,
          funnelStageReason,
          applicantProfile: { communication: { emailAddress = {}, phone = {} }, attachments: attachedFiles } = {},
          createdDateTime,
        } = applicantData;

        const { mobilePhone } = phone || {};

        let refStatus = 'NOT_REQUESTED';
        if (profileSummary?.references?.status) {
          refStatus = profileSummary?.references?.status;
        } else if (applicantData.requestedRefereeDateTime) {
          refStatus = 'REFEREES_REQUESTED';
        }

        const eDocStatus = getMultiStatus(profileSummary?.edoc);
        const genericFormStatus = getMultiStatus(profileSummary?.genericForm);
        const personalityTestStatus = getLatestPersonalityStatus(
          candidateProfile?.personalityTestSummary?.statusHistory,
        );

        function dbsStatusBadgeText() {
          switch (dbsCheckRequestRecord?.status) {
            case 'REQUESTED':
              return 'Requested';
            case 'FAILED':
              return 'Complete';
            case 'SUCCESS':
              return 'Complete';
            case 'WITHDRAWN':
              return 'Error';
            default:
              return 'Not Requested';
          }
        }

        const detailTabs = [
          { anchor: 'appDetails', label: 'applicationDetails', permissions: ['candidate:view'] },
          { anchor: 'timeline', label: 'timeline', permissions: ['candidate:timeline:view'] },
          {
            anchor: 'onboarding',
            label: 'onboarding',
            permissions: ['candidate:onboarding:read', 'candidate:references:view', 'candidate:edoc:read'],
            items: [
              {
                anchor: 'references',
                label: 'references',
                labelComponent: (
                  <Badge className={`status_${refStatus}`.toLowerCase()} color="none">
                    <span className="align-text">{languagePack[refStatus]}</span>
                  </Badge>
                ),
                permissions: ['candidate:references:view'],
              },
              {
                anchor: 'onboarding',
                label: 'onboarding',
                labelComponent: (
                  <Badge
                    className={`status_${(profileSummary?.onboarding?.status || 'NOT_REQUESTED').toLowerCase()}`}
                    color="none"
                  >
                    <span className="align-text">
                      {languagePack[profileSummary?.onboarding?.status || 'NOT_REQUESTED']}
                    </span>
                  </Badge>
                ),
                permissions: ['candidate:onboarding:read'],
              },
              {
                anchor: 'edoc',
                label: 'edocs',
                labelComponent: (
                  <Badge className={`status_${eDocStatus.toLowerCase()}`} color="none">
                    <span className="align-text">{languagePack[eDocStatus] || eDocStatus}</span>
                  </Badge>
                ),
                permissions: ['candidate:edoc:read'],
              },
            ],
          },
          {
            anchor: 'compliance',
            label: 'Compliance',
            permissions: ['rtw:read', 'dbs:read'],
            items: [
              {
                anchor: 'rightToWork',
                label: 'rtwCheck',
                labelComponent: (
                  <>
                    <Badge
                      className={`status_${(profileSummary?.rightToWork?.status || 'NOT_REQUESTED').toLowerCase()}`}
                      color="none"
                    >
                      <span className="align-text">
                        {languagePack[profileSummary?.rightToWork?.status || 'NOT_REQUESTED']}
                      </span>
                    </Badge>
                    {/* eslint-disable-next-line no-mixed-operators */}
                    {(profileSummary?.rightToWork?.data?.outcomeStatus === 'CONTINUOUS' ||
                      profileSummary?.rightToWork?.data?.outcomeStatus === 'TIME_LIMITED') && (
                      <IconSVG className="ms-2 rtw-outcome-icon-success" name="Tick" />
                    )}
                    {(profileSummary?.rightToWork?.data?.outcomeStatus === 'REJECTED' ||
                      profileSummary?.rightToWork?.status === 'WITHDRAWN') && (
                      <IconSVG className="ms-2 rtw-outcome-icon-failed" name="Cross" />
                    )}
                  </>
                ),
                permissions: ['rtw:read'],
              },
              {
                anchor: 'dbsCheck',
                label: 'dbsCheck',
                labelComponent: (
                  <>
                    {dbsCheckRequestRecord?.status === 'SUCCESS' && (
                      <IconSVG className="ms-2 dbs-outcome-icon-success" name="Tick" />
                    )}
                    {dbsCheckRequestRecord?.status === 'FAILED' && (
                      <IconSVG className="ms-2 dbs-outcome-icon-failed" name="Cross" />
                    )}
                    <Badge
                      className={`status_${(dbsCheckRequestRecord?.status || 'NOT_REQUESTED').toLowerCase()}`}
                      color="none"
                    >
                      <span className="align-text">{dbsStatusBadgeText(dbsCheckRequestRecord?.status)}</span>
                    </Badge>
                  </>
                ),
                permissions: ['dbs:read'],
              },
            ],
          },
          {
            anchor: 'genericforms',
            label: 'genericforms',
            labelComponent: (
              <Badge className={`status_${genericFormStatus.toLowerCase()}`} color="none">
                <span className="align-text">{languagePack[genericFormStatus] || genericFormStatus}</span>
              </Badge>
            ),
            permissions: ['candidate:genericforms:read'],
          },
          {
            anchor: 'personalityTest',
            label: 'personalityTest',
            labelComponent: (
              <Badge className={`status_${(personalityTestStatus || 'NOT_REQUESTED').toLowerCase()}`} color="none">
                <span className="align-text">
                  {
                    languagePack[
                      personalityTestStatus === 'PART_RECEIVED'
                        ? 'AWAITING_REPORTS'
                        : personalityTestStatus || 'NOT_REQUESTED'
                    ]
                  }
                </span>
              </Badge>
            ),
            permissions: ['pt:read', 'pth:read'],
          },
          {
            anchor: 'chat',
            label: 'chat',
            permissions: ['chat:viewer'],
          },
        ];

        if (profileSummary?.invites?.count) {
          detailTabs.splice(2, 0, {
            anchor: 'interview',
            label: 'interviews',
            permissions: ['candidate:interviewnotes:read'],
          });
        }

        let atsAttachments = attachedFiles.filter((attachment) => attachment.attachmentType === 'ATS_UPLOAD');
        if (sliderData[id] && sliderData[id].attData) {
          atsAttachments = [...sliderData[id].attData];
        }

        if (atsAttachments.length > 0) {
          detailTabs.push({
            additionalLabel: `(${atsAttachments.length})`,
            anchor: 'attachments',
            label: 'attachments',
            permissions: ['candidate:attachments:read'],
          });
        }

        if (profileSummary?.notes?.count) {
          detailTabs.push({
            additionalLabel: `(${profileSummary.notes.count})`,
            anchor: 'notes',
            label: 'notes',
            permissions: ['candidate:notes:read'],
          });
        }

        if (otherApps.length > 0) {
          detailTabs.push({
            additionalLabel: `(${otherApps.length})`,
            anchor: 'otherApps',
            label: 'Other Applications',
            permissions: ['candidate:view'],
          });
        }

        return (
          <Container fluid>
            <Row className="slider-header-row pb-4">
              <Col className="col-4">
                <CandidateContactSummary
                  key={notesKey}
                  completedApplicationForm={applicantData}
                  hasTitles={false}
                  languagePack={languagePack}
                />
              </Col>
              <Col>
                <div className="candidate-comms mt-2 bg-white rounded">
                  <Row>
                    <Col>
                      <Tabs
                        activeTab={activeTab}
                        languagePack={languagePack}
                        onClick={(tabId) => setActiveTab(tabId)}
                        positionAbsolute={false}
                        tabs={candidateToolTabs}
                        updateUrl={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="tab-wrapper">
                        {activeTab === 'funnel' && (
                          <FunnelContext.Consumer>
                            {({ onFunnelChange }) => (
                              <FunnelManager
                                applicantEmail={[{ applicantEmail: emailAddress, applicantId: id }]}
                                applicantFunnelStageId={funnelStageId}
                                applicantId={id}
                                profileSummary={profileSummary}
                                data={sliderData[id] ? sliderData[id].funnelData : { leftPos: 0 }}
                                funnelId={funnelId}
                                funnelStageReason={funnelStageReason}
                                funnelStages={funnelStages}
                                isLoadingStages={isLoadingStages}
                                onChange={(stageId, leftPos, stageLabel, stageReason) => {
                                  dispatchSliderData({
                                    id,
                                    payload: {
                                      funnelStageReason: stageReason,
                                      leftPos,
                                      stageId,
                                    },
                                    type: UPDATE_FUNNEL,
                                  });
                                  onFunnelChange(id, stageLabel, stageId, stageReason);
                                }}
                                onError={onError}
                                onSuccess={onSuccess}
                              />
                            )}
                          </FunnelContext.Consumer>
                        )}
                        {activeTab === 'email' && (
                          <EmailForm
                            applicants={[{ applicantEmail: emailAddress, applicantId: id }]}
                            data={sliderData[id] ? sliderData[id].emailData : {}}
                            isSaveAsAllowed={checkPermissions(['admin:emails:create'])}
                            isTextEditAllowed={checkPermissions(['communication:candidate:update'])}
                            onChange={(formData) => {
                              dispatchSliderData({
                                id,
                                payload: formData,
                                type: UPDATE_EMAIL,
                              });
                            }}
                            onError={onError}
                            onSuccess={onSuccess}
                          />
                        )}
                        {activeTab === 'interview' && (
                          <InterviewForm
                            applicants={[{ accountId, applicantEmail: emailAddress, applicantId: id }]}
                            changeCandidateViewTab={() => setActiveDetailsTab('interview')}
                            isInSlider={false}
                            onError={onError}
                            onSuccess={(msg) => {
                              onSuccess(msg);
                              handleSummaryUpdate();
                            }}
                          />
                        )}
                        {activeTab === 'attachments' && (
                          <AttachmentForm
                            accept={{
                              'application/msword': ['.doc'],
                              'application/pdf': ['.pdf'],
                              'application/rtf': ['.rtf'],
                              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                              'application/x-rtf': ['.rtf'],
                              'image/jpeg': ['.jpeg', '.jpg'],
                              'image/png': ['.png'],
                              'text/richtext': ['.rtf'],
                            }}
                            applicantId={id}
                            onError={onError}
                            onSuccess={onSuccess}
                            pushChange={(newFiles) => {
                              dispatchSliderData({
                                id,
                                payload: [...atsAttachments, ...newFiles],
                                type: UPDATE_ATTACHMENTS,
                              });
                            }}
                          />
                        )}
                        {activeTab === 'notes' && (
                          <NoteForm
                            accountId={accountId}
                            applicantId={id}
                            languagePack={languagePack}
                            onError={onError}
                            onSuccess={onSuccess}
                            pushChange={(value) => {
                              if (value.length) {
                                setProfileSummary({
                                  ...(profileSummary || {}),
                                  notes: {
                                    count: (((profileSummary || {}).notes || {}).count || 0) + 1,
                                  },
                                });
                                // refresh notes
                                setNotesKey(Math.random().toString(10));
                              }
                            }}
                          />
                        )}
                        {activeTab === 'sms' && (
                          <SMSForm
                            applicants={[
                              {
                                applicantId: id,
                                applicantName: fullName,
                                applicantPhone: mobilePhone,
                              },
                            ]}
                            data={sliderData[id] ? sliderData[id].smsData : ''}
                            onChange={(value) => {
                              dispatchSliderData({
                                id,
                                payload: value,
                                type: UPDATE_SMS,
                              });
                            }}
                            onError={onError}
                            onSuccess={onSuccess}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="slider-body-row">
              <Col className="col-3">
                <Tabs
                  activeTab={activeDetailsTab}
                  className="candidate-slider-tabs"
                  isVertical
                  languagePack={languagePack}
                  onClick={(tabId) => setActiveDetailsTab(tabId)}
                  positionAbsolute={false}
                  tabs={detailTabs}
                  updateUrl={false}
                />
              </Col>
              <Col className="col-9">
                {activeDetailsTab === 'appDetails' && (
                  <CandidateDetails
                    accountName={accountName}
                    applicant={applicantData}
                    vacancyAddress={
                      vacancies[vacancyId]?.address?.locationDescription ||
                      vacancies[vacancyId]?.positionProfile?.location?.metadata?.description
                    }
                    embedded={embedded}
                    // activeAccountId={activeAccountId}
                    onMove={() => {
                      onClose(true);
                    }}
                    onUpdate={(updatedProfile, updatedAnswers) => {
                      onApplicantUpdate({
                        ...applicantData,
                        applicantProfile: { ...updatedProfile },
                        ...(updatedAnswers ? { mappedAnswers: { ...updatedAnswers } } : {}),
                      });
                    }}
                    vacancyTitle={vacancyTitle}
                  />
                )}
                {activeDetailsTab === 'timeline' && (
                  <CandidateTimeline accountId={applicantData.accountId} candidateId={id} />
                )}
                {activeDetailsTab === 'references' && (
                  <ReferenceDetailsContainer
                    applicant={applicantData}
                    onApplicantUpdate={(candidateObj) => {
                      if (candidateObj) onApplicantUpdate(candidateObj);
                      handleSummaryUpdate();
                    }}
                  />
                )}
                {activeDetailsTab === 'rightToWork' && (
                  <RightToWorkDetails
                    candidate={applicantData}
                    onError={onError}
                    onSuccess={onSuccess}
                    summary={profileSummary?.rightToWork}
                    updateSummary={(status) => {
                      setProfileSummary({ ...profileSummary, rightToWork: { status } });
                      handleSummaryUpdate();
                    }}
                  />
                )}
                {activeDetailsTab === 'dbsCheck' && (
                  <>
                    <DbsCheckDetails
                      candidate={applicantData}
                      vacancy={vacancies[vacancyId]}
                      onError={onError}
                      onSuccess={onSuccess}
                      summary={dbsCheckRequestRecord}
                      updateSummary={(status) => {
                        setDbsCheckRequestRecord({ ...dbsCheckRequestRecord, status });
                      }}
                      onboardingStatus={profileSummary?.onboarding?.status}
                    />
                  </>
                )}
                {activeDetailsTab === 'personalityTest' && (
                  <PersonalityTestDetails
                    candidate={applicantData}
                    onError={onError}
                    onSuccess={onSuccess}
                    summary={candidateProfile?.personalityTestSummary}
                    updateSummary={(statusHistory) => {
                      setCandidateProfile({
                        ...candidateProfile,
                        personalityTestSummary: {
                          statusHistory,
                        },
                      });
                    }}
                  />
                )}
                {activeDetailsTab === 'onboarding' && (
                  <OnboardingDetails
                    applicant={applicantData}
                    candidateProfileSummary={profileSummary}
                    onError={onError}
                    onSuccess={onSuccess}
                    onUpdate={(status) => {
                      if (status) {
                        handleSummaryUpdate();
                        setProfileSummary({
                          ...(profileSummary || {}),
                          onboarding: { status },
                        });
                      }
                    }}
                  />
                )}
                {activeDetailsTab === 'edoc' && (
                  <EDocDetailsContainer
                    candidate={applicantData}
                    vacancy={vacancies[vacancyId]}
                    onError={onError}
                    onSuccess={onSuccess}
                    summary={profileSummary?.eDocs}
                    updateSummary={(status) => {
                      setProfileSummary({ ...profileSummary, eDocs: { status } });
                      handleSummaryUpdate();
                    }}
                    vacancyTitle={vacancyTitle}
                  />
                )}
                {activeDetailsTab === 'genericforms' && (
                  <GenericFormsContainer
                    candidate={applicantData}
                    onError={onError}
                    onSuccess={onSuccess}
                    summary={profileSummary?.genericForm}
                    updateSummary={(status) => {
                      setProfileSummary({ ...profileSummary, genericForm: { status } });
                      handleSummaryUpdate();
                    }}
                    vacancyTitle={vacancyTitle}
                  />
                )}
                {activeDetailsTab === 'interview' && (
                  <InterviewsContainer
                    applicant={applicantData}
                    candidateEmail={emailAddress}
                    candidateId={id}
                    invites={profileSummary?.invites}
                    onInviteChange={handleSummaryUpdate}
                  />
                )}
                {activeDetailsTab === 'notes' && <CandidateNotes key={notesKey} applicantId={id} />}
                {activeDetailsTab === 'chat' && (
                  <ChatContainer
                    candidate={{
                      applicantId: id,
                      applicantName: fullName,
                      applicantPhone: mobilePhone,
                      applicationDT: createdDateTime,
                    }}
                  />
                )}
                {activeDetailsTab === 'attachments' && (
                  <AttachmentsContainer
                    applicantId={id}
                    attachments={atsAttachments}
                    onError={onError}
                    onSuccess={onSuccess}
                    pushDelete={(deletedAttachment) => {
                      dispatchSliderData({
                        id,
                        payload: deleteAttachment(atsAttachments, deletedAttachment),
                        type: UPDATE_ATTACHMENTS,
                      });
                    }}
                  />
                )}
                {activeDetailsTab === 'otherApps' && <OtherApplicationsContainer otherApps={otherApps} />}
              </Col>
            </Row>
          </Container>
        );
      }}
    </CandidateSlider>
  );
}

CandidateView.propTypes = {
  accountName: PropTypes.string,
  applicantId: PropTypes.string,
  defaultDetailsTab: PropTypes.string,
  defaultTab: PropTypes.string,
  embedded: PropTypes.bool,
  funnelId: PropTypes.string,
  handleCandidateUpdate: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  userId: PropTypes.string,
  vacancies: PropTypes.shape(),
  vacancyId: PropTypes.string,
  vacancyTitle: PropTypes.string,
  applyAddVacancy: PropTypes.func,
  emailAddress: PropTypes.string,
};

CandidateView.defaultProps = {
  accountName: null,
  applicantId: undefined,
  defaultDetailsTab: null,
  defaultTab: null,
  embedded: false,
  emailAddress: null,
  funnelId: 'DEFAULT',
  handleCandidateUpdate: () => {},
  isOpen: false,
  onClose: () => {},
  userId: undefined,
  vacancies: null,
  vacancyId: null,
  vacancyTitle: null,
  applyAddVacancy: () => {},
};

function mapDispatchToProps(dispatch) {
  return {
    applyAddVacancy: (vac, id) => {
      dispatch(addVacancy({ ...vac, id }));
    },
  };
}

function mapStateToProps(state) {
  const {
    vacancy: { vacancies },
  } = state;

  return {
    vacancies,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CandidateView));
