import React, { useRef } from 'react';
import JoditEditor from 'jodit-pro-react';
import { getCommonHeaders } from '../../../../../api/common-api-utils';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import config from '../../../../../config/config';

const { api } = config;

function stripBase64Prefix(dataStr) {
  if (!dataStr) return '';
  const parts = dataStr.split('base64,');
  return [...parts].pop();
}

// File warning function similar to the one in React-Quill
function fileWarning(files) {
  if (files.length) {
    const totalSize = [...files].reduce((acc, { size = 0 }) => acc + size, 0);
    if (totalSize > 1000000) {
      toast.error(
        "Sorry the images you've included should be no more than 1MB in size. Please reduce the image size and try again",
      );
    }
  }
}

export default function HTMLEditorEDoc({ schema, uiSchema, required, formData, onChange }) {
  const editor = useRef(null);
  const DD_ID = 'keywords';
  const DD_LABEL = 'Personalisation';

  const handleChange = (content) => {
    onChange(content?.replace(/&nbsp;/g, ' '));
  };

  const editorConfig = {
    readonly: false,
    height: 400,
    toolbarSticky: true,
    toolbarAdaptive: false,
    language: 'en',
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    defaultActionOnPaste: JoditEditor.INSERT_AS_HTML, // Use 'INSERT_AS_TEXT' to clear formatting
    pasteFromWord: {
      enable: true,
      convertUnitsToPixel: false,
      allowedStyleProps: [
        'background',
        'background-color',
        'border',
        'border-.*',
        'color',
        'font-family',
        'font-size',
        'font-style',
        'font-weight',
        'height',
        'line-height',
        'list-style-type',
        'margin',
        'margin-bottom',
        'margin-left',
        'margin-right',
        'margin-top',
        'padding',
        'text-align',
        'text-decoration',
        'text-indent',
        'vertical-align',
        'width',
      ],
    },
    beautifyHTML: true,
    buttons: [
      'exportDocs',
      'source',
      'preview',
      '|',
      'bold',
      'strikethrough',
      'underline',
      'italic',
      'eraser',
      'superscript',
      'subscript',
      '|',
      'ul',
      'ol',
      '|',
      'outdent',
      'indent',
      'hr',
      '|',
      'font',
      'fontsize',
      'brush',
      'paragraph',
      '|',
      'image',
      'video',
      'file',
      'table',
      'link',
      '|',
      'align',
      'undo',
      'redo',
      '|',
      'print',
      'selectall',
      'cut',
      'copy',
      'paste',
      '|',
      DD_ID, // Custom dropdown button
    ],
    pdf: {
      allow: true,
      options: {
        format: 'A4',
        page_orientation: 'portrait',
      },
    },
    extraButtons: ['lineHeight', 'find'],
    uploader: {
      insertImageAsBase64URI: false,
      headers: { ...getCommonHeaders(), Accept: 'application/json, text/plain, */*' },
      url: `${api.middlewareAPIURL}/file`,
      format: 'json',
      method: 'POST',
      sendFiles: (files) => {
        fileWarning(files);
        return files;
      },
      buildData: function (data) {
        return new Promise(function (resolve, reject) {
          var reader = new FileReader();
          const file = data.getAll('files[0]')[0];

          if (file.size > 15 * 1024 * 1024) {
            reject('File size exceeds the limit of 15 MB.');
            return;
          }

          reader.readAsDataURL(file);
          reader.onload = function () {
            return resolve({
              content: stripBase64Prefix(reader.result),
              filename: file.name,
              accessPermission: 'PUBLIC',
              encoding: 'UTF-8',
              contentType: file.type,
            });
          };
          reader.onerror = function (error) {
            reject(error);
          };
        });
      },
      isSuccess: function (resp) {
        return true;
      },
      getMessage: function (resp) {
        return resp.message || '';
      },
      contentType: function (aaa) {
        return 'application/json; charset=UTF-8';
      },
      process: function (resp) {
        return {
          files: [resp.externalUrl],
          error: 0,
          message: 'Success',
        };
      },
      error: function (e) {
        console.error('Upload error:', e.message);
      },
      defaultHandlerSuccess: function (data, resp) {
        var i,
          field = 'files';
        const _this = this.jodit ?? this;
        if (data[field] && data[field].length) {
          for (i = 0; i < data[field].length; i += 1) {
            _this.s.insertImage(data[field][i]);
          }
        }
      },
    },
    placeholder: 'Start typing...',
    beautifyHTML: true,
    controls: {
      [DD_ID]: {
        tooltip: DD_LABEL,
        list: {
          'Candidate First Name': 'Candidate First Name',
          'Candidate Surname': 'Candidate Surname',
          'Candidate Email Address': 'Candidate Email Address',
          'Candidate Phone Number': 'Candidate Phone Number',
          'Candidate Postal Address': 'Candidate Postal Address',
          'Employer Email Address': 'Employer Email Address',
          'Employer Name': 'Employer Name',
          'Vacancy Location': 'Vacancy Location',
          'Vacancy Postcode': 'Vacancy Postcode',
          'Vacancy Title': 'Vacancy Title',
        },
        exec: (editor, _, { control }) => {
          const value = control.args && control.args[0];
          if (value) {
            const modifiedValue = `{${value.toLowerCase().replace(/ /g, '_')}}`;
            editor.s.insertHTML(modifiedValue);
          }
        },
        childTemplate: (editor, key, value) => `<span>${editor.i18n(value)}</span>`,
      },
      mobileView: {
        list: [
          { value: 320, title: 'iPhone 5' },
          { value: 360, title: 'iPhone 6' },
          { value: 768, title: 'iPad' },
        ],
      },
      exportDocs: {
        tooltip: 'Export',
        list: {
          exportToPdf: 'Export to PDF',
        },
        command: 'exportToPDF',
      },
    },
    extraPlugins: [
      'symbols',
      'exportDocs',
      'specials',
      'speech-recognize',
      'line-height',
      'font',
      'audio',
      'find',
      'highlight',
      'class-span',
      'add-new-line',
      'clipboard',
      'fix-xml',
      'resize-handler',
      'inline-popup',
      'color',
      'drag-and-drop',
      'drag-and-drop-element',
      'size',
      'dtd',
      'table',
      'image',
      'image-properties',
      'file',
      'video',
      'media',
      'clean-html',
      'custom-command',
      'link',
      'paste',
      'paste-from-word',
      'paste-storage',
      'source',
      'select',
      'stat',
      'tab',
      'justify',
      'limit',
      'preview',
      'search',
      'enter',
      'sticky',
      'placeholder',
      'focus',
      'wrap-nodes',
      'mobile',
      'debug',
      'error-messages',
      'delete-action',
      'tooltip',
      'direction',
      'order-list',
      'undo-redo',
      'redoundo',
      'indent',
      'progress-bar',
      'xpath',
      'speech-recognize',
      'spellcheck',
      'format-block',
      'symbols',
      'print',
      'about',
      'backspace',
      'cut',
      'copyformat',
      'add-new-line',
      'autofocus',
      'markdown',
    ],
  };

  return (
    <>
      {schema?.title && (
        <label className="control-label" htmlFor="root_referenceCode">
          {schema.title}
          {required && <span className="required">*</span>}
        </label>
      )}
      <JoditEditor ref={editor} value={formData} config={editorConfig} tabIndex={1} onBlur={handleChange} />
    </>
  );
}

HTMLEditorEDoc.propTypes = {
  schema: PropTypes.object.isRequired,
  required: PropTypes.bool,
  formData: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

HTMLEditorEDoc.defaultProps = {
  required: false,
  formData: '',
};
