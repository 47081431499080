/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col, CardBody, Input, Label } from 'reactstrap';

import { checkPermissions } from '@JS/auth/AuthUtils';
import { setAllTags, addTag, deleteTags, editTag } from '@JS/actions/tagActions';
import { tagsList } from '@JS/language/pages';

import { CreateButton, DeleteButton } from '@Base/Buttons';
import { getTags, deleteTags as bulkDeleteTags, createNewTag, editTagName, sortTags } from '@Base/Tags/utils';
import { Confirmation } from '@Base/Modal';
import EditInline from '@Base/Forms/Custom/EditInline/EditInline';
import { useLanguage, useMounted } from '@Base/hooks';

import { EnhancedCard, EnhancedCardTitle, PageColumn } from '../../Common';

function TagManager({ tags, tagsLoaded, setTags, deleteSelectedTags, addNewTag, editTagLabel }) {
  const isMounted = useMounted();
  const { langPack: languagePack } = useLanguage(tagsList);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [newTagLabel, setNewTagLabel] = useState();

  const isAllowedToCreateTags = checkPermissions(['tags:write']);

  useEffect(() => {
    if (isMounted() && !tags.length && !tagsLoaded) {
      getTags(
        (tagArr) => setTags(tagArr),
        () => toast.error('Error fetching tags'),
      );
    }
  }, [isMounted, setTags, tags, tagsLoaded]);

  useEffect(() => {
    getTags(
      (tagArr) => setTags(tagArr),
      () => toast.error('Error fetching tags'),
    );
  }, []);

  function handleChange(id, checked) {
    let updated = [...selectedTags];

    if (!checked) {
      updated = updated.filter((tagId) => tagId !== id);
    } else {
      const selectedTag = tags.find((tag) => tag.id === id);
      updated.push(selectedTag.id);
    }

    setSelectedTags(updated);
  }

  function handleDelete() {
    bulkDeleteTags(
      selectedTags,
      () => {
        deleteSelectedTags(selectedTags);
        setSelectedTags([]);
        toast.success(languagePack.deleteTagSuccess);
      },
      () => toast.error(languagePack.deleteTagError),
    );
  }

  function handleNameChange(id, newName) {
    editTagName(
      id,
      newName,
      () => {
        editTagLabel(id, newName);
        toast.success(languagePack.updateTagSuccess);
      },
      () => toast.error(languagePack.updateTagError),
    );
  }

  if (!checkPermissions(['candidate:tags'])) return null;

  return (
    <PageColumn>
      <EnhancedCard>
        <EnhancedCardTitle
          title="Tags"
          subtitle="Tag your candidates with useful information you can filter and search on"
        />
        <CardBody>
          <Row>
            <Col>
              <div className="tag-grid">
                {sortTags(tags).map(({ id, name }) => {
                  const selected = selectedTags.includes(id);

                  return (
                    <div key={id} className="tag-checkbox-label">
                      <input
                        id={id}
                        type="checkbox"
                        checked={selected}
                        onChange={(e) => handleChange(id, e.target.checked)}
                      />
                      <div
                        className="edit-wrapper"
                        onClick={() => handleChange(id, !selected)}
                        onKeyUp={() => handleChange(id, !selected)}
                        role="button"
                        tabIndex="0"
                      >
                        <EditInline value={name} onChange={(newName) => handleNameChange(id, newName)} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col xs="3">
              <DeleteButton
                label={languagePack.deleteSelectedBtnLabel}
                className="w-100"
                floatRight={false}
                disabled={!selectedTags.length}
                action={() => setShowConfirm(true)}
              />
              <hr />
              <Label for="new-tag">{`${languagePack.newTagLabel}:`}</Label>
              <Input id="new-tag" value={newTagLabel || ''} onChange={(e) => setNewTagLabel(e.target.value)} />
              <CreateButton
                label={languagePack.createTagBtnLabel}
                disabled={!isAllowedToCreateTags}
                className="mt-2 w-100"
                floatRight={false}
                action={() => {
                  if (!isAllowedToCreateTags) return;
                  if (newTagLabel.length) {
                    // Regular expression to check for special characters
                    const invalidCharRegex = /[!#$%'()*+,/;=?@[\]"<>\\^`{|}]/g;

                    if (invalidCharRegex.test(newTagLabel)) {
                      // Toast an error if the tag name contains invalid characters for a URL
                      toast.error('Tag name contains invalid characters, please remove them');
                      return; // Exit the function if invalid characters are found
                    }
                    createNewTag(
                      newTagLabel,
                      (tag) => {
                        addNewTag(tag);
                        setNewTagLabel();
                        toast.success(languagePack.createTagSuccess);
                      },
                      (err) => {
                        let msg = languagePack.createTagError;

                        if (err === 'ALREADY_EXISTS_ERROR') {
                          msg = languagePack.createTagErrorExists;
                        }

                        toast.error(msg);
                      },
                    );
                  }
                }}
              />
            </Col>
          </Row>
        </CardBody>
      </EnhancedCard>
      <Confirmation
        title={languagePack.deleteConfirmTitle}
        content={languagePack.deleteConfirmContent}
        show={showConfirm}
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={() => {
          setShowConfirm(false);
          handleDelete();
        }}
      />
    </PageColumn>
  );
}

TagManager.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  tagsLoaded: PropTypes.bool,
  setTags: PropTypes.func,
  deleteSelectedTags: PropTypes.func,
  addNewTag: PropTypes.func,
  editTagLabel: PropTypes.func,
};

TagManager.defaultProps = {
  tags: [],
  tagsLoaded: false,
  setTags: () => {},
  deleteSelectedTags: () => {},
  addNewTag: () => {},
  editTagLabel: () => {},
};

function mapStateToProps(state) {
  const { tags } = state;
  return {
    tags: tags.tags || [],
    tagsLoaded: tags.tagsLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTags: (tags) => {
      dispatch(setAllTags(tags));
    },
    addNewTag: (tag) => {
      dispatch(addTag(tag));
    },
    deleteSelectedTags: (tagIds) => {
      dispatch(deleteTags(tagIds));
    },
    editTagLabel: (tagId, tagLabel) => {
      dispatch(editTag(tagId, tagLabel));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TagManager);
